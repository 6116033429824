/* Judge Management Container */
.judge-management-container {
    padding: 30px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    font-size: 28px;
    color: #333;
    margin-bottom: 40px;
  }
  
  /* Error Styling */
  .error {
    color: red;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Forms Section */
  .forms-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .form-box {
    width: 48%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .form-box input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
  }
  
  .form-box button {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .form-box button:hover {
    background-color: #45a049;
  }
  
  /* Tables Section */
  .tables-container {
    display: flex;
    justify-content: space-between;
  }
  
  .table-box {
    width: 48%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table th,
  table td {
    padding: 12px;
    text-align: left;
    font-size: 16px;
    border-bottom: 1px solid #ddd;
  }
  
  table th {
    background-color: #4CAF50;
    color: white;
  }
  
  table tr:hover {
    background-color: #f9f9f9;
  }
  
  .delete-btn {
    padding: 8px 12px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }
  
  .delete-btn:focus {
    outline: none;
  }
  