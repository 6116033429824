.case-management-container {
    margin: 20px;
  }
  
  h1 {
    font-size: 32px;
    color: #333;
    text-align: center;
  }
  
  .forms-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .form-box {
    width: 45%;
    padding: 15px;
    border: 2px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-box h2 {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .form-box input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-box button {
    width: 100%;
    padding: 10px;
    background-color: #1B5693;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-box button:hover {
    background-color: #0056b3;
  }
  
  .tables-container {
    display: flex;
    justify-content: space-between;
  }
  
  .table-box {
    width: 45%;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 15px;
  }
  
  .table-box table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-box th,
  .table-box td {
    padding: 12px;
    text-align: left;
  }
  
  .table-box th {
    background-color: #1B5693;
    color: white;
  }
  
  .table-box tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table-box .delete-btn {
    padding: 6px 12px;
    background-color: #DC3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .table-box .delete-btn:hover {
    background-color: #c82333;
  }
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }
  