/* EditModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f8f9fa;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
  }
  
  .close-button:hover {
    color: #000;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .form-section {
    margin-bottom: 25px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    background-color: #fafafa;
  }
  
  .form-section h3 {
    margin-top: 0;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    color: #444;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 15px;
  }
  
  .form-group {
    flex: 1 0 200px;
    margin: 0 10px 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #555;
  }
  
  input[type="text"],
  input[type="number"],
  input[type="date"],
  input[type="tel"],
  input[type="time"],
  select,
  textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
    border-color: #4c9aff;
    box-shadow: 0 0 0 2px rgba(76, 154, 255, 0.2);
  }
  
  select:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  textarea {
    min-height: 80px;
    resize: vertical;
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cancel-button,
  .save-button {
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.1s;
  }
  
  .cancel-button {
    background-color: #f1f1f1;
    border: 1px solid #d9d9d9;
    color: #555;
  }
  
  .save-button {
    background-color: #4caf50;
    border: 1px solid #43a047;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #e0e0e0;
  }
  
  .save-button:hover {
    background-color: #43a047;
  }
  
  .cancel-button:active,
  .save-button:active {
    transform: translateY(1px);
  }