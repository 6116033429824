/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  color: #333;
}

.form-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-right: 3rem;
}

.form-container h1 {
  text-align: center;
  color: #d32f2f; 
  font-size: 2rem;
  margin-bottom: 20px;
}

.form-section h2 {
  color: #d32f2f; 
  border-bottom: 2px solid #d32f2f;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

/* Form Section */
.form-section {
  margin-bottom: 30px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  min-width: 250px;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #1B5693; /* Red color for labels */
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #d32f2f; /* Red border on focus */
  outline: none;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

/* Submit Button */
.submit-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #d32f2f; /* Red background for button */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #b71c1c; /* Darker red on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-group {
    min-width: 100%;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .form-container {
    padding: 15px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 14px;
  }

  .submit-button {
    font-size: 16px;
  }
}