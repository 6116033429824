.layout {
  display: flex;
  flex-direction: row;
}

.main-content {
  margin-left: 280px; 
  padding: 25px;
  width: calc(100% - 280px); 
  background-color: #f4f4f4;
  min-height: 100vh;
  box-sizing: border-box;
}

.layout.no-sidebar .main-content {
  margin-left: 0; /* Remove margin */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2477bf;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%;
  }
}
