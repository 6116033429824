.table-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .search-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .responsive-table th {
    background-color: #0056b3 !important; 
    color:#fff; 
    text-align: left;
    font-weight: bold;
    padding: 12px;
    font-size: 18px;
}

  .search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-right: 10px;
  }

  .search-btn {
    padding: 10px 20px;
    background-color: #0056b3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .search-btn:hover {
    background-color: #0056b3;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table th, 
  .responsive-table td {
    border: 1px solid #ddd;
    padding: 12px;
    font-size: 18px;
  }
  
  .responsive-table th {
    background-color: #f4f4f4;
    text-align: left;
    font-weight: bold;
  }
  
  .responsive-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .actions {
    display: flex;
    gap: 10px;
  }
  
  .view-btn {
    background-color: #0562a3; /* Green background */
    color: white; /* White text */
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  .view-btn:hover {
    background-color: #0562a3; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
 /* Edit Button */
.edit-btn {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.edit-btn:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.edit-btn .icon {
  margin-right: 5px; /* Space between icon and text */
}

/* Delete Button */
.delete-btn {
  background-color: #f44336; /* Red background */
  color: white; /* White text */
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.delete-btn:hover {
  background-color: #e53935; /* Darker red on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.delete-btn .icon {
  margin-right: 5px; /* Space between icon and text */
}

/* Common button styles */
button {
  outline: none;
  border: none;
  font-family: 'Arial', sans-serif;
}
 
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 10px 15px;
    margin: 0 5px;
    background-color: #0056b3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .responsive-table th,
    .responsive-table td {
      font-size: 14px;
      padding: 10px;
    }
  
    .search-input {
      font-size: 14px;
    }
  
    .search-btn, .pagination button {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
  

  