/* Sidebar Container */
.sidebar {
  width: 280px;
  height: 100vh;
  background: #1B5693;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

/* Toggle Button */
.toggle-btn {
  position: fixed;
  top: 20px;
  left: 20px;
  background: #1B5693;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  z-index: 1100;
  transition: background 0.3s;
}

.toggle-btn:hover {
  background: #154A7D;
}

/* Sidebar Header */
.sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  background: white;
  color: #1B5693;
}

/* Logo */
.sidebar-logo {
  width: 90px;
  height: 90px;
  object-fit: cover;
  transition: transform 0.3s;
}

.sidebar-logo:hover {
  transform: scale(1.1);
}

/* Title */
.sidebar-title h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0;
}

.sidebar-title p {
  font-size: 14px;
  margin: 0;
  color: #555;
}

/* Sidebar Menu */
.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}

.sidebar-menu a,
.menu-link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-decoration: none;
  color: white;
  font-size: 17px;
  font-weight: 500;
  transition: background 0.3s, padding-left 0.3s;
}

.sidebar-menu a:hover,
.menu-link:hover {
  background: #154A7D;
  padding-left: 25px;
}

/* Active Link */
.active {
  background: #0E3B6E;
  border-left: 4px solid white;
}

/* Icons */
.icon {
  margin-right: 12px;
  font-size: 18px;
}

/* Submenu */
.submenu {
  list-style: none;
  padding-left: 35px;
  margin-top: 5px;
}

.submenu a {
  padding: 12px 15px;
}

/* Arrow */
.arrow {
  margin-left: auto;
  transition: transform 0.3s;
}
