/* Full-screen container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2477bf;
  }
  
  /* Login Box */
  .login-box {
    background: #f2f4f7;
    padding: 30px;
    width: 350px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Logo */
  .logo {
    width: 80px;
    margin-bottom: 15px;
  }
  
  /* Heading Styles */
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Nepali Text */
  .nepali-text {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
  
  /* Input Fields */
  input {
    width: 100%;
    padding: 8px;
    margin: 8px 0 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Labels */
  label {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
  }
  
  /* Login Button */
  button {
    width: 100%;
    padding: 10px;
    background: #c00;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background: #900;
  }
  