/* ViewModal.css */

.view-modal-overlay {
   position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px); /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
   overflow: auto;
  }
  
  .view-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 800px;
    position: relative;
    z-index: 10000; /* Make sure modal content is above the overlay */
    max-height: 100vh; /* Set max height for the modal */
    overflow-y: auto; 
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
   
  }
  
  .close-btn {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .case-section {
    margin-bottom: 20px;
  }
  
  .case-section h3 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #f44336;
  }
  
  .case-section p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .case-section p strong {
    color: #0056b3;
  }
  .print-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .print-btn:hover {
    background-color: #0056b3;
  }
  